import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import SignInForm from 'components/SignInForm'
import Title from 'components/Title'
import ErrorBox from 'components/ErrorBox'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    margin: theme.spacing(2, 0),
  },
}))

type Props = {
  onSuccess: (token: string) => void
}

export const SignInRoute = ({ onSuccess }: Props) => {
  const classes = useStyles()
  const [error, setError] = React.useState<string | null>(null)

  return (
    <>
      {error && <ErrorBox error={error} />}
      <Title title="Log in 👋" />
      <SignInForm
        onError={setError}
        onSuccess={onSuccess}
      />
      <Link
        to="/forgot-password"
        className={classes.link}
      >
        Forgot password?
      </Link>
    </>
  )
}

export default SignInRoute
