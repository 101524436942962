import { useEffect, useRef } from 'react'

// @ts-expect-error typing
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'

type Props = {
  challenge: string
  clientId: string
  consent?: boolean
  redirectUri: string
  responseType: string
  state: string
  token?: string
}

const OAuthForm = ({ challenge, clientId, consent, redirectUri, responseType, state, token }: Props) => {
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (token && consent !== undefined && formRef.current) {
      formRef.current.submit()
    }
  }, [consent, token, formRef])

  return (
    <form
      ref={formRef}
      action="/api/oauth2/authorize"
      method="POST"
    >
      <input
        type="hidden"
        name="consent"
        value={consent === true ? 'accepted' : 'denied'}
      />
      <input
        type="hidden"
        name="challenge"
        value={challenge}
      />
      <input
        type="hidden"
        name="client_id"
        value={clientId}
      />
      <input
        type="hidden"
        name="redirect_uri"
        value={redirectUri}
      />
      <input
        type="hidden"
        name="response_type"
        value={responseType}
      />
      <input
        type="hidden"
        name="state"
        value={state}
      />
      <input
        type="hidden"
        name="token"
        value={token}
      />
    </form>
  )
}

const urlPropsQueryConfig = {
  challenge: { type: UrlQueryParamTypes.string, queryParam: 'challenge' },
  clientId: { type: UrlQueryParamTypes.string, queryParam: 'client_id' },
  redirectUri: { type: UrlQueryParamTypes.string, queryParam: 'redirect_uri' },
  responseType: { type: UrlQueryParamTypes.string, queryParam: 'response_type' },
  state: { type: UrlQueryParamTypes.string, queryParam: 'state' },
}

export default addUrlProps({ urlPropsQueryConfig })(OAuthForm)
