import { useEffect, useState, useCallback } from 'react'
import { Button, TextField, makeStyles } from '@material-ui/core'
import { Formik, Form, Field, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { useLogin } from 'common/login-service'
import { SOURCES, COBRAND_ALIAS } from 'common/coalition-sources'

const useStyles = makeStyles((theme) => ({
  signInForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
}))

type SignInFormValues = {
  email: string
  password: string
}
type SignInFormProps = {
  onError: (error: string | null) => void
  onSuccess?: (token: string) => void
  scid?: number
  source?: 'C' | 'CM'
}

interface IErr {
  status: 403 | 404
  data: string
  text: () => Promise<string>
}

type TLoginPromise = (email: string, password: string, params?: string) => Promise<IErr | null>

const LOGIN_ERRORS = {
  403: 'Invalid Username or Password',
  404: 'An error occured while signing in',
}

const SignInForm = ({ onError, onSuccess, scid, source }: SignInFormProps) => {
  const classes = useStyles()
  const [params, setParams] = useState('')

  useEffect(() => {
    const params: { [k: string]: any } = {}
    if (scid) {
      params.scid = scid
    }
    if (source) {
      params.source = COBRAND_ALIAS[source]
      if (!SOURCES.includes(COBRAND_ALIAS[source])) {
        setParams('')
      } else {
        setParams(`?${new URLSearchParams(params).toString()}`)
      }
    }
  }, [source, scid])

  const login = useLogin(onSuccess) as TLoginPromise

  const performLogin = useCallback(
    (values: SignInFormValues, props: FormikHelpers<SignInFormValues>) => {
      props.setSubmitting(true)
      return login(values.email, values.password, params).catch((err) => {
        if (err) {
          props.setSubmitting(false)
          const errorMessage = LOGIN_ERRORS[err.status as 403 | 404] || err.data
          if (!errorMessage) {
            err.text().then((text: string) => onError(text))
          } else {
            onError(errorMessage)
          }
        }
      })
    },
    [onError, params, login]
  )

  const initialValues = {
    email: '',
    password: '',
  }

  const yupValidation = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email.').required('This is Required.'),
    password: Yup.string().required('This is Required.'),
  })

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={yupValidation}
      onSubmit={performLogin}
    >
      {({ errors, touched, isValid, isSubmitting }) => (
        <Form className={classes.signInForm}>
          <Field
            id="email"
            name="email"
            as={TextField}
            required
            autoFocus
            fullWidth
            variant="outlined"
            label="Email"
            error={errors.email && touched.email}
          />
          <Field
            id="password"
            type="password"
            name="password"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="Password"
            error={errors.password && touched.password}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting}
          >
            Log In
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default SignInForm
