import { Switch, Route } from 'react-router-dom'
import SignIn from './signin'
import Consent from './consent'
import React from 'react'
import OAuthForm from './OAuthForm'
import { useHistory } from 'react-router-dom'

const Routes = () => {
  const [token, setToken] = React.useState<string>()
  const [consent, setConsent] = React.useState<boolean | undefined>(undefined)
  const history = useHistory()

  const handleLogin = (token: string) => {
    setToken(token)
    history.push('/oauth2/consent')
  }

  return (
    <>
      <OAuthForm
        token={token}
        consent={consent}
      />
      <Switch>
        <Route path="/oauth2/consent">
          <Consent
            clientName="CGN"
            clientDescription="CGN is an integrated college and career solution that leads students and families through the entire college and career planning process."
            infoLink="https://www.collegeguidancenetwork.com/"
            onConsent={setConsent}
          />
        </Route>
        <Route path="/oauth2/signin">
          <SignIn onSuccess={handleLogin} />
        </Route>
      </Switch>
    </>
  )
}

export default Routes
